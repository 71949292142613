import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Config } from "../../Config";
import { GalaController } from '../../controllers/GalaController';
import { GalaTicketSaleController } from "../../controllers/GalaTicketSaleController";
import { IGalaTicketListingSchema, PaymentType, IContactSchema, IGalaTicketSchema } from "../../model/Gala/IGala";
import { Dict } from "../../model/utils";
import CheckoutForm from './CheckoutForm';
import './Tickets.scss';

export const amountToString = (amount: number) => {
    if (amount < 0) return `-$${Math.abs((amount / 100)).toFixed(2)}`;
    return `$${(amount / 100).toFixed(2)}`;
};

const Tickets = () => {

    const headRef = useRef<HTMLDivElement>(null);
    const scrollToHead = () => {
        if (headRef !== null) {
            headRef.current?.scrollIntoView();
        }
    }
    // ----------------------------------------------------------------
    // Local States
    // ----------------------------------------------------------------

    // Tickets
    const [tickets, setTickets] = useState<Dict<number>>({});
    const [ticketListings, setTicketListings] = useState<IGalaTicketListingSchema[]>([]);

    const calcTicketCount = (tLid?: string) => {
        if (ticketListings.length === 0) return 0;
        let total = 0;
        Object.keys(tickets).forEach((tId) => {
            if (tLid === tId) return;
            const qty = tickets[tId];
            const targetTicketListing = ticketListings.find((tL) => (
                tL._id === tId
            ));
            if (targetTicketListing === undefined) throw new Error('cannot find target listing');
            Object.values((targetTicketListing.galaTicketMap)).forEach((val) => total += val * qty);
        })

        return total;
    }

    const updateTickets = (e: React.ChangeEvent<HTMLSelectElement>, tLId: string) => {
        const selCount = parseInt(e.target.value, 10);
        setTickets((prevState) => ({
            ...prevState,
            [tLId]: selCount,
        }));
    };

    // Donations
    const [donation, setDonation] = useState(0);
    const [donationOpen, setDonationOpen] = useState(true);
    const updateDonation = (e: any) => {
        const val = parseFloat(e.target.value);
        setDonation(val);
    }

    const updateDonationSelect = (n: number) => {
        setDonation(n);
        setDonationOpen(false);
    }

    const openDonation = () => {
        setDonation(0);
        setDonationOpen(true);
    }
    // Contact
    const contactRef = useRef<HTMLDivElement>(null);
    const scrollToContact = () => {
        if (contactRef !== null) {
            contactRef.current?.scrollIntoView();
        }
    }
    const [contact, setContact] = useState<IContactSchema>({
        fName: '',
        lName: '',
        address: '',
        postalCode: '',
        city: '',
        province: '',
        country: '',
        email: '',
        phone: '',
    })
    const updateContact = (e: any, field: any) => {
        const val = e.target.value;
        setContact((prevState) => ({
            ...prevState,
            [field]: val,
        }))
    }

    // Seating arrangement
    // const [seating, setSeating] = useState('');
    // const updateSeating = (e: any) => {
    //     const val = e.target.value;
    //     setSeating(val);
    // };
    // ----------------------------------------------------------------
    // Stripe
    // ----------------------------------------------------------------
    const stripePromise = loadStripe(Config.STRIPE_PUBLIC_API);
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: 'stripe' as const,
    };
    const options = {
        clientSecret,
        appearance,
    };

    // ----------------------------------------------------------------
    // Payment
    // ----------------------------------------------------------------
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.INIT);
    const changePaymentType = (e: any) => {
        const pType = e.target.value;
        setPaymentType(pType);
    };

    const [success, setSuccess] = useState(false);

    const renderPayment = () => {
        switch (paymentType) {
            case PaymentType.CARD:
                return (
                    <div className="row">
                        {clientSecret && (
                            <div className="col-md-12">
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm success={success} setSuccess={setSuccess} submitData={()=>console.log(123)}/>
                                </Elements>
                            </div>
                        )}
                    </div>
                );
            case PaymentType.CHEQUE:
                return (
                    <div className="row col-md-12">
                        <h6>Please make cheque payable to “SDC” and mail to P.O BOX 97073, Richmond, BC, V6X 8H3.</h6>
                    </div>
                )
            case PaymentType.CASH:
                return (
                    <div className="row col-md-12">
                        <h6>
                            Please contact David Chen at
                            {' '}
                            <a href="mailto: DavidChen@SocialDiversity.org">DavidChen@SocialDiversity.org</a> or
                            {' '}
                            <a href="tel: +18882475071">+1-888-247-5071</a>
                        </h6>
                    </div>
                )
            default:
                return <></>;
        }
    };

    // ----------------------------------------------------------------
    // Helper Functions
    // ----------------------------------------------------------------

    const calcTotal = () => {
        if (ticketListings.length === 0) return 0;
        let total = 0;
        Object.keys(tickets).forEach((tId) => {
            const qty = tickets[tId];
            const targetTicketListing = ticketListings.find((tL) => (
                tL._id === tId
            ));
            if (targetTicketListing === undefined) throw new Error('cannot find target listing');
            total += targetTicketListing.totalPrice * qty;
        })

        return total + donation * 100;
    }

    const showPaymentForm = () => (
        !success && calcTotal() > 0
    );

    const validField = (key: keyof IContactSchema) => {
        const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const phoneFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        const val = contact[key];
        if (val === undefined) return false;
        if (val.length === 0) return false;
        if (key === 'email' && !val.match(mailFormat)) return false;
        if (key === 'phone' && !val.match(phoneFormat)) return false;
        return true;
    }

    const validContactForm = () => {
        let valid = true;
        Object.keys(contact).forEach((tKey) => {
            const key = tKey as keyof IContactSchema;
            if (!validField(key)) valid = false; 
        });
        return valid;
    }

    // ----------------------------------------------------------------
    // Controller Access
    // ----------------------------------------------------------------
    const galaController = new GalaController();
    const galaTicketSaleController = new GalaTicketSaleController();

    const createGalaTicketSale = async () => {
        if (!validContactForm()) {
            scrollToContact();
            return;
        }
        try {
            const galaTicketSaleResponse = await galaTicketSaleController.createGalaTicketSale(
                tickets,
                paymentType,
                donation * 100,
                contact,
            );
            const tClientSecret = galaTicketSaleResponse.data.galaTicketSale.clientSecret; 
            if (tClientSecret !== null) {
                setClientSecret(tClientSecret);
            }
            if (paymentType !== PaymentType.INIT && paymentType !== PaymentType.CARD) {
                setSuccess(true);
            }
        } catch (err) {
            // pass
            console.log(err);
        }
    };

    // ----------------------------------------------------------------------------
    // Use Effect Hooks
    // ----------------------------------------------------------------------------
    // On page load, init tickets and ticketListings
    useEffect(() => {
        scrollToHead();
        const getTicketListings = async () => {
            const galaResponse = await galaController.getGala();
            const tTickets: Dict<number> = {};
            galaResponse.data.gala.galaTicketListings.forEach((tL) => {
                tTickets[tL._id] = 0;
            });
            
            setTickets(tTickets);
            const tTicketListings = galaResponse.data.gala.galaTicketListings;
            const uTicketListings: IGalaTicketListingSchema[] = tTicketListings.map((tL) => {
                const firstKey = Object.keys(tL.galaTicketMap)[0];
                return ({
                    ...tL,
                    totalTickets: tL.galaTicketMap[firstKey],
                })
            });
            setTicketListings(uTicketListings);
        };
        getTicketListings();
    }, []);

    const generateDropdown = (tL: IGalaTicketListingSchema) => {
        let tLTicketCount = 0;
        Object.values((tL.galaTicketMap)).forEach((val) => tLTicketCount += val);
        if (tLTicketCount === 0) return <></>;
        // Calc available quantity
        const availCount = Math.floor((10 - calcTicketCount(tL._id)) / tLTicketCount);
        console.log('availcount', availCount);
        if (availCount === 0) return <p>Max Exceeded</p>
        return (tL.isForSale && (
            <select onChange={(e) => updateTickets(e, tL._id)} className="form-control" id="">
                <option value={0} selected>0</option>
                {Array.from(Array(availCount).keys()).map((count) => (
                    <option value={count+1}>{count+1}</option>
                ))}
            </select>
        ))
    }

    return (
        <section className="section-tickets" ref={headRef}>
            {!success && (
                <div>
                    {/* Tickets */}
                    <div className="container reminder">
                        <div className="row col-12">
                            <h3>Please check your email after purchase to register your tickets and choose your meal. If no meal is chosen, the default option will be provided</h3>
                        </div>
                    </div>
                    <div className="tickets-container container">
                        <div className="row">
                            <div className="col-12 headingContainer">
                                <div className="headingWrapper">
                                    <h2 className="heading">Tickets</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container reminder">
                            <div className="row col-12">
                                <h4>A maximum of ten tickets, or one table, could be purchased at one time. If you wish to purchase more, please repeat the process multiple times.</h4>
                            </div>
                        </div>
                        <div className="container reminder">
                            <h4>Current Number of Tickets Selected: {calcTicketCount()}</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {ticketListings.filter((t) => t.isForSale || t.available).map((tL) => (
                                    <div className="ticket-stub">
                                        <div className="ticketDescWrapper">
                                            <div className="ticketDesc">
                                                <h4>{tL.name.en}</h4>
                                                <p>
                                                    {tL.desc.en}
                                                </p>
                                            </div>
                                            {generateDropdown(tL)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Donations */}
                    <div className="donations-container container">
                        <div className="row">
                            <div className="col-12 headingContainer">
                                <div className="headingWrapper">
                                    <h2 className="heading">Donations</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="donation row">
                                    <div className="donationDesc">
                                        <h4>Donations are always welcome!</h4>
                                        <p>
                                            Any contribution will support SDC&apos;s mission of providing children of
                                            all abilities with quality social learning programs and safe, inclusive
                                            environments to empower them to be whomever they aspire to be. All
                                            donations will be eligible for a tax receipt of the equivalent amount
                                            and you will be recognized on the night of the gala.
                                        </p>
                                    </div>
                                    <div className="optionRow">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" onClick={() => updateDonationSelect(500)}/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">$500</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onClick={() => updateDonationSelect(1000)}/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">$1,000</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" onClick={() => updateDonationSelect(2500)}/>
                                            <label className="form-check-label" htmlFor="inlineRadio3">$2,500</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option1" onClick={() => updateDonationSelect(5000)}/>
                                            <label className="form-check-label" htmlFor="inlineRadio4">$5,000</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="option2" onClick={() => updateDonationSelect(10000)}/>
                                            <label className="form-check-label" htmlFor="inlineRadio5">$10,000</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio6" value="option3" checked={donationOpen}/>
                                            <label className="form-check-label" htmlFor="inlineRadio6" onClick={openDonation}>Other</label>
                                        </div>
                                    </div>
                                    
                                    {donationOpen && (
                                    <div className="otherAmount">
                                        <input value={donation} onChange={updateDonation} type="number" className="form-control" min="0"/>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Seating */}
                    {/* <div className="seating-container container">
                        <div className="row">
                            <div className="col-12 headingContainer">
                                <div className="headingWrapper">
                                    <h2 className="heading">Seating</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="seating row">
                                    <div className="seatingDesc col-md-12">
                                        <h4>We want you to be with your friends!</h4>
                                        <p>
                                            Please indicate who you would like to sit with - maximum
                                            10 person per table.
                                        </p>
                                        <textarea value={seating} onChange={updateSeating} className="form-control col-md-12" />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* Contact */}
                    <div className="contact-container container" ref={contactRef}>
                        <div className="row">
                            <div className="col-12 headingContainer">
                                <div className="headingWrapper">
                                    <h2 className="heading">Contact</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="contact row">
                                    <div className="contactDesc col-md-12">
                                        <h4>Enter purchaser information below:</h4>
                                        <p>
                                            The tickets will be sent out to the following contact. <br />
                                            Information entered here will be used for tax receipts.
                                        </p>
                                    </div>
                                    <div className="row purchaser">
                                        <div className="form-group col-md-6 col-sm-6">
                                            <label htmlFor={`fName_purchaser`}>Legal First Name</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'fName')}
                                                type="text"
                                                className={`form-control ${validField('fName') ? '' : 'contactInvalid'}`}
                                                id={`fName_purchaser`}
                                                placeholder="Legal First Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <label htmlFor={`lName_purchaser`}>Legal Last Name</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'lName')}
                                                type="text"
                                                className={`form-control ${validField('lName') ? '' : 'contactInvalid'}`}
                                                id={`lName_purchaser`}
                                                placeholder="Legal Last Name"
                                            />
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12">
                                            <label htmlFor={`address_purchaser`}>Address</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'address')}
                                                type="text"
                                                className={`form-control ${validField('address') ? '' : 'contactInvalid'}`}
                                                id={`address_purchaser`}
                                                placeholder="Address"
                                            />
                                        </div>
                                        <div className="form-group col-md-4 col-sm-4">
                                            <label htmlFor={`zip_purchaser`}>Postal Code</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'postalCode')}
                                                type="text"
                                                className={`form-control ${validField('postalCode') ? '' : 'contactInvalid'}`}
                                                id={`zip_purchaser`}
                                                placeholder="Postal Code"
                                            />
                                        </div>
                                        <div className="form-group col-md-4 col-sm-4">
                                            <label htmlFor={`city_purchaser`}>City</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'city')}
                                                type="text"
                                                className={`form-control ${validField('city') ? '' : 'contactInvalid'}`}
                                                id={`city_purchaser`}
                                                placeholder="City"
                                            />
                                        </div>
                                        <div className="form-group col-md-4 col-sm-4">
                                            <label htmlFor={`prov_purchaser`}>Province</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'province')}
                                                type="text"
                                                className={`form-control ${validField('province') ? '' : 'contactInvalid'}`}
                                                id={`prov_purchaser`}
                                                placeholder="Province"
                                            />
                                        </div>
                                        <div className="form-group col-md-12 col-sm-12">
                                            <label htmlFor={`country_purchaser`}>Country</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'country')}
                                                type="text"
                                                className={`form-control ${validField('country') ? '' : 'contactInvalid'}`}
                                                id={`country_purchaser`}
                                                placeholder="Country"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <label htmlFor={`email_purchaser`}>Email</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'email')}
                                                type="email"
                                                className={`form-control ${validField('email') ? '' : 'contactInvalid'}`}
                                                id={`email_purchaser`}
                                                placeholder="Email"
                                            />
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <label htmlFor={`mobile_purchaser`}>Phone</label>
                                            <input
                                                onChange={(e) => updateContact(e, 'phone')}
                                                type="text"
                                                className={`form-control ${validField('phone') ? '' : 'contactInvalid'}`}
                                                id={`phone_purchaser`}
                                                placeholder="Phone"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Payment */}
            <div className="payment-container container">
                <div className="row">
                    <div className="col-12 headingContainer">
                        <div className="headingWrapper">
                            <h2 className="heading">Payment</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="payment container">
                            <div className="paymentDesc col-md-12 d-flex justify-content-between">
                                <h4>Your total</h4>
                                <h4 className="totalAmount">{amountToString(calcTotal())}</h4>
                            </div>
                            {showPaymentForm() && (
                                <>
                                    {!success && <div className="form-group row d-flex align-items-center">
                                        <h5 className="col-md-6">Payment Method</h5>
                                        <select className="col-md-6 form-control" onChange={changePaymentType}>
                                            <option selected value='' disabled>Select a payment method</option>
                                            <option value={PaymentType.CARD}>Card</option>
                                            <option value={PaymentType.CHEQUE}>Cheque</option>
                                            <option value={PaymentType.CASH}>Cash</option>
                                        </select>
                                    </div>}

                                    <div className="continuePayment">
                                        {renderPayment()}
                                    </div>
                                    {paymentType !== PaymentType.INIT && clientSecret === '' && (
                                        <button onClick={createGalaTicketSale}>Continue</button>
                                    )}
                                </>
                            )}
                            {success && paymentType === PaymentType.CARD && (
                                <div className="col-md-12 d-flex align-items-center thankYou">
                                    <h6>Your payment has been received! You will be sent an email
                                        with your tickets attached.</h6>
                                </div>
                            )}
                            {success && paymentType !== PaymentType.CARD && (
                                <div className="col-md-12 d-flex align-items-center thankYou">
                                    <h6>
                                        Your information has been recorded! You will receive an email
                                        with your tickets when the payment are processed.
                                    </h6>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tickets;