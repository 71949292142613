import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import AboutGala from '../components/About/AboutGala';
import AboutBrivia from '../components/About/AboutBrivia';

import AuctionPreview from '../components/Auctions/AuctionPreview';
import Sponsors from '../components/Sponsors/Sponsors';
import Footer from '../components/Footer/Footer';
import ModalMenu from '../components/Modal/ModalMenu';
import AboutCurv from '../components/About/AboutCurv';

class ThemeOne extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Hero />
                <AboutGala />
                <AboutCurv />
                <AboutBrivia />
                <Sponsors />
                <AuctionPreview />
                {/* <SDC />
                <Auctions /> */}
                {/* <TopSeller /> */}
                {/* <Collections /> */}
                {/* <Explore /> */}
                {/* <Work /> */}
                <Footer />
                {/* <ModalSearch /> */}
                <ModalMenu />
                {/* <Scrollup /> */}
            </div>
        );
    }
}

export default ThemeOne;