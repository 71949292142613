import React, { Component } from 'react';
import './AuctionPreview.scss';
const initData = {
    pre_heading: "Auctions",
    heading: "Auction Preview",
    btnText: "View All"
}

const data = [
    {
        id: "100",
        img: "https://cboimages.com/ClickBidImages/5527/100_0_z2auo3dn9l.png",
        title: "Raffle Ticket",

        valueText: "Value:",
        value: '$100'
    },
    {
        id: "101",
        img: "https://cboimages.com/ClickBidImages/5527/113_0_dghl0su2qw.jpg",
        title: "Mont Blanc Pen (Rose Gold)",
        startingText: "Starting:",
        starting: "$401",

        valueText: "Value:",
        value: '$535'
    },
    {
        id: "102",
        img: "https://cboimages.com/ClickBidImages/5527/117_0_r5fjv6ouw4.jpg",
        title: '"Sea Skin" by Yvon Julivet',
        startingText: "Starting:",
        starting: "$1,200",

        valueText: "Value:",
        value: '$1,600'
    },
    {
        id: "103",
        img: "https://cboimages.com/ClickBidImages/5527/110_0_73xlv8zmjo.jpg",
        title: "Infamous Whiskey- 22 Years Old",
        startingText: "Starting:",
        starting: "$1,200",

        valueText: "Value:",
        value: '$1,600'
    },
    {
        id: "104",
        img: "https://cboimages.com/ClickBidImages/5527/124_0_teqr95a8lw.jpeg",
        title: "Santos de Cartier Biplane Cufflinks",
        startingText: "Starting:",
        starting: "$1,125",

        valueText: "Value:",
        value: '$1,500'
    }
]

class AuctionPreview extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section-auction-preview" id="auction">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{this.state.initData.heading}</h3>
                                </div>
                                {/* <div className="intro-btn">
                                    <a className="btn content-btn" href="/auctions">{this.state.initData.btnText}</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="auctions-slides">
                        <div className="swiper-container slider-mid items">
                            <div className="swiper-wrapper">
                                {/* Single Slide */}
                                {this.state.data.map((item, idx) => {
                                    return (
                                        <div key={`auc_${idx}`} className="swiper-slide item">
                                            <div className="card">
                                                <div className="image-over">
                                                    <a href="/item-details">
                                                        <img className="card-img-top" src={item.img} alt="" />
                                                    </a>
                                                </div>
                                                {/* Card Caption */}
                                                <div className="card-caption col-12 p-0">
                                                    {/* Card Body */}
                                                    <div className="card-body">

                                                        {/* <a href="/item-details"> */}
                                                        <h5 className="mb-0">{item.title}</h5>
                                                        {/* </a> */}
                                                        <div className="card-bottom d-flex justify-content-between">
                                                            <span>{item.startingText}</span>
                                                            <span><b>{item.starting}</b></span>
                                                        </div>
                                                        <div className="card-bottom d-flex justify-content-between">
                                                            <span>{item.valueText}</span>
                                                            <span><b>{item.value}</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="swiper-pagination" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AuctionPreview;