import { Config } from "../Config";
import { GalaFields, GalaTicketSalesFields } from "../model/Gala/GalaFields";
import { IGalaTicketSaleResponse, IContactSchema } from "../model/Gala/IGala";
import { Dict } from "../model/utils";
import { AxiosController } from "./AxiosController";


export class GalaTicketSaleController extends AxiosController {
    private PATH: string = 'gala/ticketSale';

    public async getGalaTicketSales(
        galaTicketSaleId: string,
    ) : Promise<IGalaTicketSaleResponse> {
        const galaTickeSaleResponse = await this.axiosGET<IGalaTicketSaleResponse>(
            `${Config.REMOTE_SERVER_ADDR}/${this.PATH}`,
            {
                [GalaTicketSalesFields.galaTicketSaleId]: galaTicketSaleId
            },
        );
        return galaTickeSaleResponse;
    }

    public async createGalaTicketSale(
        galaTicketListingMap: Dict<number>,
        paymentType: string,
        donationAmount: number,
        purchaser: IContactSchema,
    ) : Promise<IGalaTicketSaleResponse> {
        const galaTickeSaleResponse = await this.axiosPOST<IGalaTicketSaleResponse>(
            `${Config.REMOTE_SERVER_ADDR}/${this.PATH}`,
            {
                [GalaTicketSalesFields.galaId]: Config.GALA_ID,
                [GalaTicketSalesFields.galaTicketListingMap]: galaTicketListingMap,
                [GalaTicketSalesFields.paymentType]: paymentType,
                [GalaTicketSalesFields.donationAmount]: donationAmount,
                [GalaTicketSalesFields.fName]: purchaser.fName,
                [GalaTicketSalesFields.lName]: purchaser.lName,
                [GalaTicketSalesFields.address]: purchaser.address,
                [GalaTicketSalesFields.postalCode]: purchaser.postalCode,
                [GalaTicketSalesFields.city]: purchaser.city,
                [GalaTicketSalesFields.province]: purchaser.province,
                [GalaTicketSalesFields.country]: purchaser.country,
                [GalaTicketSalesFields.email]: purchaser.email,
                [GalaTicketSalesFields.phone]: purchaser.phone,
            },
        );
        return galaTickeSaleResponse;
    }
}