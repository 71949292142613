import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import logoImg from '../../assets/logo.png';

import './Header.scss';

const Header = () => {
    const [isHome, setIsHome] = useState(true);
    useEffect(() => {
        const addSlash = window.location.pathname.includes('buy-tickets') || window.location.pathname.includes('redeem');
        setIsHome(!addSlash);
    }, [window.location.pathname])
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos-delay={800} className="navbar navbar-expand">
                <div className="container header">
                    {/* Navbar Brand*/}
                    {/* <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src="img/logo.png" alt="sticky brand-logo" />
                    </a> */}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src={logoImg} alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link" href={`${isHome ? '#' : '/#'}gala`}>About Gala</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link"  href={`${isHome ? '#' : '/#'}curv`}>About Curv</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link"  href={`${isHome ? '#' : '/#'}brivia`}>About Brivia</a>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link" href="/">Sponsors</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link"  href={`${isHome ? '#' : '/#'}auction`}>Auction</a>
                        </li>
                        {/* <li className="nav-item">
                            <a href="/" className="nav-link">Donate</a>
                        </li> */}
                    </ul>


                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>

                    {/* Navbar Action Button */}
                    <ul className="navbar-nav action">
                        <li className="nav-item ml-3">
                            <Link className="btn ml-lg-auto btn-bordered-white" to="/buy-tickets">
                                <i class="fa fa-ticket-alt" aria-hidden="true"></i>
                                Buy Tickets
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;