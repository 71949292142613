import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './AboutGala.scss';


import slider1Img from '../../assets/about/slider1.jpg';
import slider2Img from '../../assets/about/slider2.jpg';
import slider3Img from '../../assets/about/slider3.jpg';
import slider4Img from '../../assets/about/slider4.jpg';
import slider5Img from '../../assets/about/slider5.jpg';


class AboutGala extends Component {
    state = {}
    componentDidMount(){
        //
    }
    render() {
        return (
            <section className="section-about-gala" id="gala">
                <div className="container">
                    <iframe src="https://www.youtube.com/embed/eNC5RJOfpbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="about-gala container">
                    <div className="row">
                        <div className="col-12 headingContainer">
                            <div className="headingWrapper">
                                <h2 className="heading">About Gala</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            On September 3rd, 2022, SDC will be hosting the SDC 2022 Brivia Once Upon a Shine Gala Presented by Baker West and Vancouver Career College. Join us at Westin Bayshore this evening to celebrate SDC’s journey and achievements over the past year! As well, this gala will provide a stage for children of all abilities to share their story, showcase their music and art from programs, and inspire. Hence, don’t miss out on this inspiring and meaningful event that “works towards creating a vision of a larger puzzle that incorporates all members of society and not just those that fit into an outdated mold” (David Wang, President of SDC). 
                            <br/>
                            <br/>
                            We are planning a lot for this gala, from exhibitions and performances to  speeches and auctions. All proceeds from this event will go towards our social programs, and other initiatives to empower children to empower children with disabilities. A magical evening filled with incredible talent, heart-warming stories, and disability empowerment awaits you! Buy tickets with just a click of a button below!
                        </div>
                        <div className="buy-tickets-wrapper col-12 d-flex justify-content-center align-items-center">
                            {/* <div> */}
                                <Link className="btn ml-lg-auto btn-bordered-white" to="/buy-tickets">
                                    <i class="fa fa-ticket-alt" aria-hidden="true"></i>
                                    Buy Tickets
                                </Link>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="about-sdc container">
                    <div className="sdcHeadingWrapper">
                        <div className="sdcHeading">
                            <div className="headingContainer">
                                <div className="headingWrapper">
                                    <h2 className="heading">About SDC</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row content">
                        <div className="col-12">
                            <div className="auctions-slides">
                                <div className="swiper-container slider-mid items">
                                    <div className="swiper-wrapper">
                                        {/* Single Slide */}
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider1Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider2Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider3Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider4Img} alt="" />
                                        </div>
                                        <div key={`auc_123`} className="swiper-slide item">
                                            <img className="card-img-top" src={slider5Img} alt="" />
                                        </div>
                                    </div>
                                    <div className="swiper-pagination" />
                                </div>
                            </div>
                            <p>
                                SDC is a Canadian charity that empowers youth to empower children with disabilities. SDC amplifies the voices of passionate young leaders who believe in equal opportunity, regardless of ability. Our mission is to empower youth to empower children with disabilities by educating younger generations about inclusivity and acceptance.
                            </p>
                            <p>
                                At the core of SDC is our social programs for children with disabilities: Building Bridges with Music, Education Through Creativity, Education Through Virtual Reality, Drama for Diversity, and MPM/JELIC. Learn more about each program on {` `}
                                <a className="sdcWeb" href="https://www.socialdiversity.org/">our website!</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutGala;