import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
import BuyTickets from '../themes/buy-tickets';
import Redeem from '../themes/redeem';


class MyRoutes extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeOne} />
            <Route path="/buy-tickets" component={BuyTickets} />
            <Route path="/redeem/:id" component={Redeem} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRoutes;