export enum GalaFields {
    galaId = 'galaId'
}

export enum GalaTicketSalesFields {
    galaTicketSaleId = 'galaTicketSaleId',
    galaId = 'galaId',
    galaTicketListingMap = 'galaTicketListingMap',
    paymentType = 'paymentType',
    donationAmount = 'donationAmount',
    fName = 'fName',
    lName = 'lName',
    address = 'address',
    postalCode = 'postalCode',
    city = 'city',
    province = 'province',
    country = 'country',
    email = 'email',
    phone = 'phone',
}

export enum GalaAttendeefields {
    galaAttendeeId = 'galaAttendeeId',
    registered = 'registered',
    galaId = 'galaId', 
    galaTicketId = 'galaTicketId',
    galaRaffles = 'galaRaffles',
    galaDonations = 'galaDonations',
    fName = 'fName',
    lName = 'lName',
    address = 'address',
    postalCode = 'postalCode',
    city = 'city',
    province = 'province',
    country = 'country',
    email = 'email',
    phone = 'phone',

    pName = 'pName',
    mealChoice = 'mealChoice',
    dietRestriction = 'dietRestriction',
    seatingNotes = 'seatingNotes',
    notes = 'notes',
}